

import React, { useEffect } from "react";
import { FaStar, FaRegStar, FaPlus } from "react-icons/fa6";
import logo from '../../images/bg_image.webp';
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import Varients from "./Varients";
import { IoCloseCircleSharp } from "react-icons/io5";
import CartAction from '../../redux/actions/CartAction';
import ProductAction from "../../redux/actions/ProductAction";
import Varientdropdown from "./Varientdropdown";
import { useMediaQuery } from "react-responsive";
import MobilVarient from "./MobileVarient";
const CardProduct = ({ discount, image, wishlist, className1, title, slug, price, status, rating, productid, mrp, variantList, stock_status, product_type }) => {
    const isDesktoporMobile = useMediaQuery({ query: `(max-width: 991px)` });
    const [searchParams, setSearchParams] = useSearchParams();
    const { addToCartProduct, deleteCart } = CartAction();
    const { getbundlelist } = ProductAction();
    const { addToWishList } = CartAction(searchParams);
    const dispatch = useDispatch();
    // useEffect(() => {
    //     if (variantList && variantList.length > 0) {
    //         // alert()
    //         dispatch({
    //             type: 'setproductvarientdata',
    //             payload: variantList[0]
    //         });
    //     }
    // }, []);

    const navigate = useNavigate();
    const addtocartpage = useSelector(state => state.cartdetail.addtocartpage);
    const productvarientdata = useSelector(state => state.masterdata.productvarientdata);


    const authStatus = useSelector(state => state.masterdata.authStatus);
    const renderStars = () => {
        let stars = [];
        for (let i = 0; i < 5; i++) {
            if (i < rating) {
                stars.push(<FaStar key={i} />);
            } else {
                stars.push(<FaRegStar key={i} />);
            }
        }
        return stars;
    };

    const handleAddToCart = (id) => {
        if (!authStatus) {
            const randomId = Math.floor(Math.random() * 100000000);
            if (localStorage.getItem("sessionId") === null) {
                localStorage.setItem('sessionId', randomId);
            }
        }
        const inputData = {
            ...(variantList && variantList.length > 0 ? { "variantid": productvarientdata.variantid } : {}),
            "product_id": id,
            "quantity": 1,
            "type": 'product',
            ...(authStatus === false && { "session_id": localStorage.getItem("sessionId") })
        };
        dispatch(addToCartProduct(inputData));


    };
    const clicked = (type, id) => {
        const input_data = {
            id: id,
            type: type,
        }
        dispatch(deleteCart(input_data, 'wishlist'))
    }
    const clickwishlist = (id, type) => {

        dispatch(addToWishList(id, type))
    }
    // useEffect(() => {
    if (addtocartpage === "Added to cart") {
        // alert()
        navigate("/cart");
        dispatch({
            type: 'setAddtocartpage',
            payload: ""
        });
        dispatch({
            type: "setcartpagerediredct",
            payload: true
        })
    }
    //  else if (addtocartpage) {
    //     // navigate(1);


    // }
    // }, [addtocartpage]);
    const bundleclick = () => {
        dispatch({
            type: "setbundleapicall",
            payload: product_type
        })
        dispatch({
            type: "setbundle_apislug",
            payload: slug
        })

    }

    return (
        <div className="single-makal-product" >

            <div className="wishlist-card-wrong">
                <NavLink
                    to={`/product/${slug}${product_type === "bundle" ? `?type=${product_type}` : ''}`}
                    className="text-decoration-none"
                >

                    {
                        <div className="pro-img image_aspect_ratio">

                            <img src={image} onError={e => e.currentTarget.src = logo} alt={title} />


                            <div className="productcard-status-discount">
                                {stock_status == 0 && <div className="sticker-new secondaryColor">{"Out Of Stock"}</div>}
                                {
                                    stock_status == 1 && discount > 0 &&
                                    (
                                        variantList && variantList.length > 0 ?
                                            (productvarientdata.rstatus == 1 &&
                                                <div className="sticker-sale primaryColor">  {Math.round(100 - (productvarientdata.selling_price / productvarientdata.mrp) * 100)}% OFF</div>
                                            )
                                            :
                                            <div className="sticker-sale primaryColor">{discount}% OFF</div>
                                    )
                                }
                            </div>
                        </div>

                    }


                </NavLink>
                {
                    wishlist &&
                    <IoCloseCircleSharp className="whishlist-wrong-button" onClick={() => clicked('cart', productid)} />
                }
            </div>
            <div className={`pro-content`}>
                <NavLink to={`/product/${slug}`} className="text-decoration-none">
                    <h6 className={`pro-title primaryColorHovercolor ${className1}`}>{title}</h6>
                </NavLink>


                {/* <Varients productid={productid} variantListdata={variantList} /> */}
                {/*...if varient .............. */}
                {
                    variantList && variantList.length > 0 && (
                        isDesktoporMobile ?
                            <MobilVarient variantList={variantList} /> :
                            <Varientdropdown variantList={variantList} />
                    )
                }
                {
                    variantList && variantList.length > 0 ?
                        <div>
                            <p className="price-category-list">
                                <span className={`price ${className1}`}>{localStorage.getItem("currency") + productvarientdata.selling_price}</span>
                                {
                                    stock_status == 0 ? "" :
                                        <>
                                            {mrp !== price && <span className="price cross-price">{localStorage.getItem("currency") + productvarientdata.mrp}</span>}
                                        </>
                                }

                            </p>
                        </div> :
                        <div>
                            <p className="price-category-list">
                                <span className={`price ${className1}`}>{localStorage.getItem("currency") + price}</span>
                                {
                                    stock_status == 0 ? "" :
                                        <>
                                            {mrp !== price && <span className="price cross-price">{localStorage.getItem("currency") + mrp}</span>}
                                        </>
                                }
                            </p>
                        </div>
                }
                <div className="pro-actions">

                    {
                        wishlist ? (
                            <div className="actions-primary">
                                <div className={`add-to-cart primaryColorHovercolor ${className1}`} data-toggle="tooltip" data-original-title="Move to Wishlist" onClick={() => clicked('move', productid)}>
                                    <FaPlus className="addcarticons" /> Move To Cart
                                </div>
                            </div>
                        ) : (

                            stock_status == 0 ?
                                <div className="actions-primary">
                                    {
                                        authStatus ?

                                            <div className={`add-to-cart primaryColorHovercolor ${className1}`} data-toggle="tooltip" data-original-title="Move to Wishlist" onClick={() => clickwishlist(productid, 'product')}>
                                                <FaPlus className="addcarticons" /> Add to Wishlist
                                            </div> :
                                            <NavLink to={"/login"} className="text-decoration-none">
                                                <div className={`add-to-cart primaryColorHovercolor ${className1}`} data-toggle="tooltip" data-original-title="Move to Wishlist">
                                                    <FaPlus className="addcarticons" /> Add to Wishlist
                                                </div>
                                            </NavLink>
                                    }

                                </div> :
                                < div className="actions-primary">
                                    <div className={`add-to-cart primaryColorHovercolor ${className1}`} data-toggle="tooltip" data-original-title="Add to Cart" onClick={() => handleAddToCart(productid)}>
                                        <FaPlus className="addcarticons" /> Add To Cart
                                    </div>
                                </div>
                        )
                    }


                    <div className="actions-secondary">
                        {rating && rating.length > 0 && <div className="rating primaryColorcolor">{renderStars()}</div>}
                    </div>
                </div>

            </div>


        </div >
    );
};

export default CardProduct;













