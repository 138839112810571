import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';

const CategoryComp2 = () => {
    const navdata = useSelector(state => state.masterdata.navdata);
    const navdatahead =navdata.filter(ele =>ele.list_key == "main_menu")

    return (
        <ul className="header-bottom-list d-flex justify-content-center">
            {navdatahead && navdatahead.length > 0 &&
                navdatahead.slice(0, 6).map(ele => (
                    <li key={ele.slug}>
                        {/* <NavLink className="drop-icon primaryColorHovercolor secondaryColorColor" to={`${ele.url}`}>
                            {ele.name}
                        </NavLink> */}

                        {ele.children && ele.children.length > 0 &&
                            <ul className="ht-dropdown text-decoration-none">
                                <Row>
                                    {ele.children.map(ele1 => (
                                        <li key={ele1.url}>
                                            <ul className='sub_sub_cat'>
                                                <NavLink className="sub_sub_category_title" to={`${ele1.url}`}>
                                                    <li className="category-menu-title primaryColorHovercolor secondaryColorColor">
                                                        {ele1.name}
                                                    </li>
                                                </NavLink>
                                                {ele1.children && ele1.children.length > 0 &&
                                                    ele1.children.map(ele2 => (
                                                        <li key={ele2.url}>
                                                            <NavLink className="sub_sub_category_title primaryColorHovercolor secondaryColorColor" to={`${ele2.url}`}>
                                                                {ele2.name}
                                                            </NavLink>
                                                        </li>
                                                    ))
                                                }
                                            </ul>
                                        </li>
                                    ))}
                                </Row>
                            </ul>
                        }
                    </li>
                ))
            }
        </ul>
    );
};

export default CategoryComp2;
